<template>
  <div>
    <!-- <h2>team individual</h2> -->
    <b-card
      class="mb-2"
      style="
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
        border-radius: 20px;
        height: auto;
        overflow-y: auto;
      "
    >
      <b-container class="bv-example-row">
        <b-row>
          <!-- column 1 -->
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <b-container class="bv-example-row">
              <b-row>
                <b-col class="d-flex justify-content-center">
                  <img
                    class="imageProfile"
                    :src="profile.image === null ? require(`@/assets/images/user.png`) : profile.image.url"
                  >
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <!-- column 2 -->
          <b-col cols="9">
            <b-row class="saj-header m-0 mb-1 mt-1 p-0">
              <p class="ellipsisProfile">
                {{ $t(name) }}
              </p>
            </b-row>
            <b-row>
              <b-col md="4">
                <p class="saj-text d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Email')"
                    class="mr-1"
                    icon="MailIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile">
                    {{ $t(email) }}
                  </span>
                </p></b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Position')"
                    class="mr-1"
                    icon="UserIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile">
                    {{ $t(position) }}
                  </span>

                </p></b-col>
              <b-col md="4">
                <p class="saj-text d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Phone Number')"
                    class="mr-1"
                    icon="PhoneIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(mobile_number) }} </span>
                </p></b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Employee Id')"
                    class="mr-1"
                    icon="ClipboardIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(employee_number) }} </span>
                </p></b-col>

              <b-col md="4">
                <p class="saj-text  d-flex">
                  <img
                    v-b-tooltip.hover.bottom="$t('Department')"
                    class="mr-1"
                    src="@/assets/images/icons/department.png"
                    width="23px"
                    height="23px"
                  >
                  <span class="ellipsisProfile">
                    {{ $t(department) }}
                  </span>
                </p></b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Supervisor')"
                    class="mr-1"
                    icon="UsersIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(supervisor) }} </span>
                </p>
              </b-col>
              <b-col md="4">
                <p class="saj-text  d-flex">
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Hire Date')"
                    class="mr-1"
                    icon="CalendarIcon"
                    size="22"
                    stroke="black"
                  />
                  <span class="ellipsisProfile"> {{ $t(DateChanged(hire_date)) }} </span>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- <div>
      <profile />
    </div> -->
    <div class="">
      <b-tabs
        v-model="currTab"
        card
      >
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 1"
        >
          <template v-if="(roles.selectedRole === 3 && userRole === 'User') || (roles.selectedRole === 4 && userRole === 'User')">
            <div v-if="setting === 0">
              <performance-part-1 />
            </div>
            <div v-else>
              <!-- // non exec kra -->
              <performance-part-exec-5 />
            </div>

          </template>
          <template v-else>
            <div>
              <!-- <performance-part-5
                :user-id="id"
              /> -->
              <summaryKRA
                class="mt-3"
                :performance-id="performance_id"
                :team-id="id"
              />
            </div>

          </template>

        </b-tab>
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 2"
        >
          <performance-part-2 />
        </b-tab>
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 3"
        >
          <performance-part-3 />
        </b-tab>
        <b-tab
          class="p-0"
          :title="$t('Part') + ' ' + 4"
        >
          <performance-part-4 />
        </b-tab>
        <b-tab
          class="p-0"
          :title="$t('Summary Score')"
        >
          <team-summaryscore
            v-if="getRole"
            :role="role"
            :gradeset="gradeset"
            :sub-id="sub"
          />
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BContainer,
  // BImg,
  //   BButton,
  BTabs,
  BTab,
  VBTooltip,
//   BFormInput,
//   BFormGroup,
//   BFormSelect,
  // BFormDatepicker,
//   BForm,
  // BAvatar,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"

import SummaryKRA from '@/views/pages/performance/my-performance/KRA/summary-kra.vue'
import PerformancePartExec5 from "@/pages/exec/JD-KRA-Team/KRA/Part-1-new.vue" // Non-Exec KRA

import PerformancePart1 from "@/views/pages/JD-KRA-Team/JD/part-1-global.vue" // Non-Exec JD
// Exec/TM KRA
// import PerformancePart5 from "@/views/pages/JD-KRA-Team/JD/part-1-global-kra.vue"
import PerformancePart2 from "@/views/pages/JD-KRA-Team/JD/part-2-global.vue"
import PerformancePart3 from "@/views/pages/JD-KRA-Team/JD/part-3-global.vue"
import PerformancePart4 from "@/views/pages/JD-KRA-Team/JD/part-4-global.vue"
import TeamSummaryscore from "@/views/pages/JD-KRA-Team/JD/team-summary-score.vue"
import { mapGetters } from 'vuex'
import moment from "moment"
// import profile from '@/component/29_profile_summary.vue'
// eslint-disable-next-line import/no-unresolved
const states = require("@/db/states.json")

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    // BImg,
    // BButton,
    BTabs,
    BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // vSelect,
    // BFormDatepicker,
    // BForm,
    // BAvatar,
    TeamSummaryscore,
    PerformancePartExec5,
    // PerformancePartTop5,

    PerformancePart1,
    PerformancePart2,
    PerformancePart3,
    PerformancePart4,
    // PerformancePart5,
    SummaryKRA,
    // profile,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      currTab: this.$route.params.tabIdx,
      getRole: false,
      userRole: '',
      setting: "",
      id: this.$route.params.id,
      performance_id: this.$route.params.performance_id,
      name: "",
      role: "Non-Executive",
      gradeset: null,
      email: "",
      position: "",
      mobile_number: "",
      employee_number: "",
      department: "",
      supervisor: "",
      branch: "",
      department_id: "",
      supervisor_id: "",
      branch_id: null,
      hire_date: "",
      stateList: states,

      profile: {
        image: '',
        full_name: "",
        mobile_number: "",
        gender: null,
        race: null,
        address: "",
        postal_code: "",
        city: "",
        position: "",
        grade: "",
        employee_number: null,
        employee_status: null,
        supervisor_id: null,
        department_id: null,
        hire_date: "",
        year_of_service: null,
        branch_id: null,
        // state: null,
      },
      isSetup: false,
      isNewLogo: false,
      newLogo: '',
      newLogoTemp: null,
      sub: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    latitude() {
      this.company.latitude = this.currLatitude
    },
    longitude() {
      this.company.longitude = this.currLongitude
    },
    isNewLogo(){
    },
  },
  beforeMount(){
  },
  mounted(){
    this.getDetails()
    this.getUserSet()
    this.currentUser()
  },
  methods: {
    DateChanged(date) {
      if (date !== '-' && date !== '') {
        const newDay = moment(date, "").format("D-MM-yyyy")
        return newDay
      }
      return '-'
    },
    currentUser(){
      const data = new FormData()
      // data.append("user_id", this.idCore)
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`, data).then(response => {
        this.userRole = response.data.data.role_name
        // console.log(response)
      })
    },
    getUserSet() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/getEmployeePerformanceSet?user_id=${this.id}`, data).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.setting = response.data.data.jd_kra

        // console.log(response)
      })
    },
    getDetails() {
      // const params = new FormData()
      // params.append("user_id", this.id)
      // console.log('userid', this.id)
      this.$axios.get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${this.id}`).then(response => {
        if (response.data.success === true){
          const profileData = response.data.data
          // console.log('pd 1', response.data)
          this.sub = profileData.employee.business_unit
          this.role = profileData.grade_set_id
          this.gradeset = profileData.grade_set_id
          this.getRole = true
          this.name = profileData.name !== null ? profileData.name : "-"
          this.email = profileData.email !== null ? profileData.email : "-"
          this.position = profileData.employee.position !== null
            ? profileData.employee.position
            : "-"
          this.mobile_number = profileData.employee.mobile_number !== null
            ? profileData.employee.mobile_number
            : "-"
          this.employee_number = profileData.employee.employee_number !== null
            ? profileData.employee.employee_number
            : "-"
          this.department = profileData.department !== null ? profileData.department : "-"
          this.supervisor = profileData.supervisor !== null ? profileData.supervisor : "-"
          this.hire_date = profileData.employee.hire_date !== null
            ? profileData.employee.hire_date
            : "-"
          this.profile.image = profileData.profile_picture.file
          // maklumat peribadi
          this.profile.full_name = profileData.employee.full_name
          this.profile.mobile_number = profileData.employee.mobile_number
        } else {
          // this.$errorConfirmation()
          this.getRole = true
          this.errorConfirmation('danger')
        }
      })
    },

    onFileChange(e) {
      const file = e.target.files[0]
      this.isNewLogo = true
      this.testData = true
      // eslint-disable-next-line prefer-destructuring
      this.newLogo = this.$refs.companyPicture.files[0]
      this.newLogoTemp = URL.createObjectURL(file)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.disable-flat-pickr{
  background-color: gray;
}
.ellipsisProfile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tajuk{
  font-size: 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}
.form{
  font-size: 22px;
  color: #000000;
}
.isi{
  font-size: 22px;
  height: 4rem;
}
.tab-title{
  font-size: 25px;
}
</style>
